document.addEventListener("DOMContentLoaded", function() {
    const links = document.querySelectorAll('.flicker-link');
    links.forEach(link => {
        const text = link.textContent;
        link.textContent = '';

        const letters = text.split('');

        letters.forEach((letter, originalIndex) => {
            const span = document.createElement('span');
            span.textContent = letter;

            const r1 = Math.random().toFixed(6);
            const r2 = Math.random().toFixed(6);

            span.style.setProperty('--r1', r1);
            span.style.setProperty('--r2', r2);

            span.classList.add('flicker-letter');
            link.appendChild(span);
        });

        if(!link.closest('.home_gif_col')){
            // Ajouter l'effet flicker au survol
            link.addEventListener('mouseenter', () => {
                link.querySelectorAll('.flicker-letter').forEach(span => {
                    span.classList.add('flickering');
                });
            });

            // Supprimer l'effet flicker lorsque la souris quitte le lien
            link.addEventListener('mouseleave', () => {
                link.querySelectorAll('.flicker-letter').forEach(span => {
                    span.classList.remove('flickering');
                });
            });
        }else{
            const gif_cols = document.querySelectorAll('.home_gif_col');
            gif_cols.forEach(gif_col => {
                gif_col.addEventListener('mouseenter', () => {
                    gif_col.querySelectorAll('.flicker-letter').forEach(span => {
                        span.classList.add('flickering');
                    });
                });
                gif_col.addEventListener('mouseleave', () => {
                    gif_col.querySelectorAll('.flicker-letter').forEach(span => {
                        span.classList.remove('flickering');
                    });
                });
            });
        }

    });

});
